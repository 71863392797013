import { Component, ViewChild } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { AppState, AppStore } from './app.store';
import { DataService } from './data.service';
import { CarInfoResult } from './data.types';

@Component({
  selector: 'ci-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AppStore],
})
export class AppComponent {
  public results$: Observable<CarInfoResult[]>;
  constructor(private dataService: DataService, private appStore: AppStore) {
    this.results$ = this.appStore.select((state: AppState) => state.reuslts);
  }

  public onSearchClicked(licenceNumber: string) {
    this.appStore.getResults(licenceNumber);
  }
}
