import { Component, Input, OnInit } from '@angular/core';
import { CarInfoResult } from '../data.types';

@Component({
  selector: 'ci-result-card',
  templateUrl: './result-card.component.html',
  styleUrls: ['./result-card.component.scss'],
})
export class ResultCardComponent implements OnInit {
  public fieldDescriptionMap = new Map<string, keyof CarInfoResult>([
    ['מספר רכב', 'mispar_rechev'],
    ['קוד תוצר', 'tozeret_cd'],
    ['סוג דגם', 'sug_degem'],
    ['שם תוצר', 'tozeret_nm'],
    ['קוד דגם', 'degem_cd'],
    ['שם דגם', 'degem_nm'],
    ['רמת גימור', 'ramat_gimur'],
    ['רמת אבזור בטיחותי', 'ramat_eivzur_betihuty'],
    ['קבוצת זיהום', 'kvutzat_zihum'],
    ['שנת ייצור', 'shnat_yitzur'],
    ['דגם מנוע', 'degem_manoa'],
    ['תאריך מבחן רכב', 'mivchan_acharon_dt'],
    ['תוקף רשיון רכב', 'tokef_dt'],
    ['סוג בעלות', 'baalut'],
    ['מספר שילדה', 'misgeret'],
    ['צבע רכב', 'tzeva_rechev'],
    ['מידות צמיג קדמי', 'zmig_kidmi'],
    ['מידות צמיג אחורי', 'zmig_ahori'],
    ['סוג דלק', 'sug_delek_nm'],
    ['מספר הוראת רישום', 'horaat_rishum'],
    ['כינוי מסחרי', 'kinuy_mishari'],
  ]);

  @Input() result: CarInfoResult;
  constructor() {}

  ngOnInit(): void {}
}
