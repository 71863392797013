import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EMPTY, Observable } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { DataService } from './data.service';
import { CarInfoResult } from './data.types';

export interface AppState {
  reuslts: CarInfoResult[];
}

@Injectable()
export class AppStore extends ComponentStore<AppState> {
  constructor(private dataService: DataService) {
    super({ reuslts: [] });
  }

  readonly getResults = this.effect((licenceNumber$: Observable<string>) => {
    return licenceNumber$.pipe(
      switchMap((licenceNumber) =>
        this.dataService.findByLicenseNumber(licenceNumber).pipe(
          tap({
            next: (results) => this.updateResults(results),
            error: (e) => console.error(e),
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  readonly updateResults = this.updater((state, results: CarInfoResult[]) => ({
    reuslts: results,
  }));
}
