import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CarInfoResult, IDataResponse } from './data.types';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private http: HttpClient) {}

  public findByLicenseNumber(
    licenseNumber: string
  ): Observable<CarInfoResult[]> {
    const queryURL = `https://data.gov.il/api/3/action/datastore_search?resource_id=053cea08-09bc-40ec-8f7a-156f0677aff3&limit=1&filters={%22mispar_rechev%22:[%22${licenseNumber}%22]}`;
    return this.http
      .get(queryURL)
      .pipe(map((response: IDataResponse) => response.result.records));
  }
}
